<!--
 * @Description: 分成比例
 * @Author: 琢磨先生
 * @Date: 2022-06-27 22:55:42
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-12-02 11:06:13
-->



<template>
  <el-card class="box ">
    <el-button type="primary" size="small" @click="onEdit()" round icon="plus"
      >新增</el-button
    >
  </el-card>
  <el-card class="box data">
    <el-table :data="tableData" border>
      <!-- <el-table-column label="编号" prop="id" width="100"> </el-table-column> -->
      <el-table-column label="名称" prop="name" width="200"> </el-table-column>
      <el-table-column label="结算方式" prop="settle_mode_text" width="140">
        <template #default="scope">
          {{ scope.row.settle_mode_text
          }}<span v-if="scope.row.settle_day > 0"
            >{{ scope.row.settle_day }}日</span
          >
        </template>
      </el-table-column>
      <el-table-column label="分成比例(%)" prop="income_rate" width="160">
        <template #default="scope">
          <div>商户：{{ scope.row.business_income_rate }}%</div>
          <div>房东：{{ scope.row.decimal_income_rate }}%</div>
        </template>
      </el-table-column>

      <el-table-column label="状态" width="100">
        <template #default="scope">
          <el-tag type="danger" v-if="scope.row.is_stop">停用</el-tag>
          <el-tag type="success" v-else>正常</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="房源数" prop="store_count" width="100">
      </el-table-column>
      <el-table-column label="描述" prop="description" min-width="200"> </el-table-column>
      <el-table-column label="创建时间" prop="create_at" width="180">
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="140">
        <template #default="scope">
          <el-button type="primary" link size="small" @click="onEdit(scope.row)"
            >修改</el-button
          >
          <el-popconfirm
            title="去定要删除？"
            @confirm="onDelete(scope.row)"
            v-if="scope.row.store_count <= 0"
          >
            <template #reference>
              <el-button type="danger" link size="small" class="text-danger"
                >删除</el-button
              >
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </el-card>

  <el-dialog
    v-model="visibleDialog"
    width="600px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="title"
  >
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label="名称" prop="name">
        <el-col :span="14">
          <el-input v-model="form.name" placeholder="请输入名称"></el-input>
        </el-col>
        <div class="help-block">名称使用后将无法修改</div>
      </el-form-item>
      <el-form-item label="房东提成(%)" prop="decimal_rate">
        <el-col :span="10">
          <el-input
            v-model="form.decimal_rate"
            placeholder="请输入百分比"
          ></el-input>
        </el-col>
        <div class="help-block">
          百分比，且不能大于100%，可精确到小数点后2位
        </div>
      </el-form-item>

      <el-form-item label="结算方式" prop="settle_mode">
        <el-radio-group v-model="form.settle_mode">
          <el-radio
            :label="item.value"
            v-for="item in modes"
            :key="item.value"
            >{{ item.text }}</el-radio
          >
        </el-radio-group>
      </el-form-item>

      <el-form-item
        label="月结日期"
        prop="settle_day"
        v-if="form.settle_mode == 2"
      >
        <el-select v-model="form.settle_day" placeholder="" clearable>
          <el-option
            :label="`${i}日`"
            :value="i"
            v-for="i in 28"
            :key="i"
          ></el-option>
        </el-select>
        <div class="help-block">月结时必须设置，当月结算上月提成</div>
      </el-form-item>

      <el-form-item label="停用" v-if="!form.is_default">
        <el-switch v-model="form.is_stop"></el-switch>
      </el-form-item>

      <el-form-item label="描述" prop="description">
        <el-col :span="20">
          <el-input
            v-model="form.description"
            type="textarea"
            placeholder="描述内容"
          ></el-input
        ></el-col>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving"
        >确定</el-button
      >
    </template>
  </el-dialog>
</template>
<script>
import common_api from "@/http/common_api";

export default {
  data() {
    return {
      tableData: [],
      loading: false,
      saving: false,
      visibleDialog: false,
      title: "",
      form: {},
      rules: {
        name: [
          {
            required: true,
            message: "请输入名称",
            trigger: "blur",
          },
        ],
        settle_mode: [
          {
            required: true,
            message: "方式必选",
            trigger: "blur",
          },
        ],
        scale: [
          {
            required: true,
            message: "请输入房东占比",
            trigger: "blur",
          },
          {
            type: "string",
            pattern: /^(100|[1-9]?[0-9](\.[0-9]{1,2})?)$/,
            message: "百分比格式错误",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.modes = res.data.income_rule_modes;
      }
    });
    this.loadData();
  },
  methods: {
    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http.post("admin/v1/incomerule").then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },

    /**
     * 新增、修改
     */
    onEdit(item) {
      this.form = {};
      this.form = Object.assign({}, item);
      this.visibleDialog = true;
    },
    /**
     * 删除
     * @param {*} item
     */
    onDelete(item) {
      this.$http.get("admin/v1/incomerule/del?id=" + item.id).then((res) => {
        if (res.code == 0) {
          this.$message({ type: "success", message: res.msg });
          this.loadData();
        }
      });
    },
    /**
     * 提交保存
     */
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("/admin/v1/incomerule/edit", this.form)
            .then((res) => {
              this.saving = false;
              if (res.code == 0) {
                this.visibleDialog = false;
                this.$message({
                  type: "success",
                  message: res.msg,
                });
                this.loadData();
              }
            })
            .cath(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

